// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-post-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\templates\\project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-pages-404-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-resume-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-secret-js": () => import("E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\pages\\secret.js" /* webpackChunkName: "component---src-pages-secret-js" */)
}

