module.exports = [{
      plugin: require('E:/Programming/Javascript/dumblole.github.io-source/dumblole.github.io-source/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"E:\\Programming\\Javascript\\dumblole.github.io-source\\dumblole.github.io-source\\src\\components\\layout.js"},
    },{
      plugin: require('E:/Programming/Javascript/dumblole.github.io-source/dumblole.github.io-source/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"qualtiy":100},
    },{
      plugin: require('E:/Programming/Javascript/dumblole.github.io-source/dumblole.github.io-source/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('E:/Programming/Javascript/dumblole.github.io-source/dumblole.github.io-source/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dumblole","short_name":"dumblole","start_url":"/","background_color":"white","theme_color":"black","display":"standalone","icon":"src/icons/favicon.png"},
    },{
      plugin: require('E:/Programming/Javascript/dumblole.github.io-source/dumblole.github.io-source/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
